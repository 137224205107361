import { useState, useEffect } from 'react';
import { 
  Table,
  Text,
  Button,
  Group,
  Stack,
  Card,
  ScrollArea,
  Loader,
  Alert 
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Play, AlertCircle } from 'lucide-react';
import axios from 'axios';
import GameReplayModal from './GameReplayModal';
import GameOutcomeBadge from './GameOutcomeBadge';

const AgentGamesHistory = ({ competitionId, attacheAgentId }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [replayModalOpen, setReplayModalOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);

  const handleReplay = async (game) => {
    try {
      if (!game.gcs_path) {
        throw new Error('No replay file available for this game');
      }

      setSelectedGame(game);
      setReplayModalOpen(true);

      notifications.show({
        title: 'Replay Started',
        message: 'Opening replay viewer...',
        color: 'blue'
      });
    } catch (error) {
      console.error('Replay error:', error);
      notifications.show({
        title: 'Replay Error',
        message: error.message,
        color: 'red'
      });
    }
  };

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get(
          `/api/direct_attache_agents/agent/${attacheAgentId}/games`
        );
        setGames(response.data.games || []);
        setError(null);
      } catch (err) {
        setError('Failed to load games history');
        notifications.show({
          title: 'Error',
          message: 'Failed to load games history',
          color: 'red'
        });
      } finally {
        setLoading(false);
      }
    };

    if (attacheAgentId) {
      fetchGames();
    }
  }, [attacheAgentId]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleString();
  };

  const formatReward = (reward) => {
    if (reward === null || reward === undefined) return 'N/A';
    return reward.toFixed(2);
  };

  if (loading) {
    return (
      <Card p="xl">
        <Group justify="center">
          <Loader />
          <Text>Loading games history...</Text>
        </Group>
      </Card>
    );
  }

  if (error) {
    return (
      <Alert icon={<AlertCircle size={16} />} title="Error" color="red">
        {error}
      </Alert>
    );
  }

  if (!games.length) {
    return (
      <Card p="xl">
        <Text ta="center" c="dimmed">
          No games history available
        </Text>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <Stack spacing="md">
          <Text size="lg" weight={500}>Recent Games History</Text>
          
          <ScrollArea>
            <Table striped highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Date</Table.Th>
                  <Table.Th>Steps</Table.Th>
                  <Table.Th>Reward</Table.Th>
                  <Table.Th>Outcome</Table.Th>
                  <Table.Th>Opponents</Table.Th>
                  <Table.Th>Actions</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {games.map((game) => (
                  <Table.Tr key={game.simulation_id || game.id}>
                    <Table.Td>{formatDate(game.created_at)}</Table.Td>
                    <Table.Td>{game.env_nb_steps || 'N/A'}</Table.Td>
                    <Table.Td>
                      {formatReward(game.agent_performance?.reward)}
                    </Table.Td>
                    <Table.Td>
                      <GameOutcomeBadge 
                        outcome={game.agent_performance?.outcome} 
                      />
                    </Table.Td>
                    <Table.Td>
                      <Stack spacing={4}>
                        {(game.opponents || []).map((opponent, idx) => (
                          <Text key={idx} size="sm">
                            {opponent.agent_name || 'Unknown'} ({formatReward(opponent.reward)})
                          </Text>
                        ))}
                      </Stack>
                    </Table.Td>
                    <Table.Td>
                      {game.gcs_path && (
                        <Button
                          leftSection={<Play size={14} />}
                          variant="light"
                          onClick={() => handleReplay(game)}
                          size="xs"
                        >
                          Replay
                        </Button>
                      )}
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </ScrollArea>
        </Stack>
      </Card>

      {selectedGame && (
        <GameReplayModal
          isOpen={replayModalOpen}
          onClose={() => {
            setReplayModalOpen(false);
            setSelectedGame(null);
          }}
          
          competitionId={competitionId}
          gcsPath={selectedGame.gcs_path}
          gameInfo={selectedGame}
          renderDelay={selectedGame.render_delay_second}
        />
      )}
    </>
  );
};

export default AgentGamesHistory;