import React from 'react';
import { Stack, Title, Text, Code, Button, Grid, Group, Alert } from '@mantine/core';
import { Github, Codesandbox, MessageCircle } from 'lucide-react';
import RuntimeSelector from 'components/DirectAgentAttach/RuntimeSelector';
import TableOfContents from 'components/Competition/TableOfContents';
import { competitionGettingStartedStyles as styles } from "theme/competitionGettingStartedStyles";
import { StyledButton } from 'theme/buttonStyles';
import { useNavigate } from 'react-router-dom';

const GettingStarted = ({
  runtimeOptions = [],
  selectedImage,
  setSelectedImage,
  competitionInfo = {
    number_of_agents: 1,
    agent_cpu_limit: '1 core',
    agent_memory_limit: '1GB',
    agent_max_time_per_step_second: 1,
    agent_storage_limit: '100MB',
    max_files: 10,
    is_real_time: true,
    notebook_blueprint_colab: null,
    github_repo: null
  },
  environmentInfo = {
    observation_shape: {},
    state_shape: {},
    possible_agents: [],
    action_mask: false,
    url_code_source: '#'
  },
  handleJoinCompetition
}) => {
  const sections = [
    { id: 'understand-your-challenge', label: 'Understand Your Challenge' },
    { id: 'submission-requirements', label: 'Submission Requirements' },
    { id: 'training', label: 'Resources To Train Your Agent' }
  ];

  return (
    <Grid>
      <Grid.Col span={9}>
        <Stack spacing={0} style={styles.root}>
          <section id="understand-your-challenge" style={styles.section}>
            <Text style={styles.sectionTitle}>Understand Your Challenge</Text>
            <HowItWorks competitionInfo={competitionInfo} />
          </section>

          <section id="submission-requirements" style={styles.section}>
            <Text style={styles.sectionTitle}>Submission Requirements</Text>
            <SubmissionRequirements 
              competitionInfo={competitionInfo}
              environmentInfo={environmentInfo}
              runtimeOptions={runtimeOptions}
              selectedRuntime={selectedImage}
              setSelectedImage={setSelectedImage}
              handleJoinCompetition={handleJoinCompetition}
            />
          </section>

          <section id="training" style={{ ...styles.section, ...styles.lastSection }}>
            <Text style={styles.sectionTitle}>Resources to train your agent</Text>
            <TrainingResources 
              githubRepo={competitionInfo.github_repo}
              colabNotebook={competitionInfo.notebook_blueprint_colab}
              competitionInfo={competitionInfo}
            />
          </section>
        </Stack>
      </Grid.Col>
      <Grid.Col span={3}>
        <TableOfContents sections={sections} />
      </Grid.Col>
    </Grid>
  );
};

const tagDescriptions = {
  "EXPERIMENTAL-BETA": {
    title: "Experimental/Beta Environment",
    description: "This environment is in beta testing and active development. While you may encounter occasional issues, your participation and feedback are invaluable for improving the platform. Perfect for contributors interested in engaging with our GitHub community to report bugs, suggest features, and help shape future development. Join us in testing and refining new AI competition formats!"
  },
  "SINGLE-PLAYER": {
    title: "Single-Player Environment",
    description: "This is a single-player environment where your agent interacts directly with the environment. The focus is on optimizing individual performance and achieving specific goals without the complexity of competing agents. Your agent needs to learn optimal policies based on the environment's feedback."
  },
  "2-PLAYERS": {
    title: "Two-Player Competition",
    description: "In this competition, two agents compete against each other simultaneously. Each agent must make decisions considering their opponent's actions, making it a dynamic and strategic environment. Success requires developing robust strategies that can adapt to different opponent behaviors."
  },
  "4-PLAYERS": {
    title: "Four-Player Competition",
    description: "A complex multi-agent environment where four players compete simultaneously. This format introduces advanced group dynamics and strategic depth, requiring agents to balance competition and potential temporary alliances. Agents must track and respond to multiple opponents while maintaining their competitive edge."
  },
  "5-PLAYERS": {
    title: "Five-Player Competition",
    description: "An intricate five-player competitive environment that tests agent adaptability and strategic complexity. With multiple opponents acting simultaneously, agents must excel at multi-agent modeling and decision-making. Success depends on sophisticated strategies that can handle diverse opponent behaviors and shifting alliances."
  },
  "REAL-TIME": {
    title: "Real-Time Interaction",
    description: "This competition operates in real-time, meaning your agent must make decisions within strict time constraints. Quick reaction times and efficient processing are crucial, as delayed responses can impact performance. Your agent needs to balance decision quality with computational efficiency."
  },
  "META-LEARNING": {
    title: "Meta-Learning Challenge",
    description: "This competition focuses on meta-learning, where your agent needs to learn how to learn efficiently. Instead of optimizing for a single task, your agent should develop strategies that allow it to quickly adapt to new, similar tasks. Success requires implementing algorithms that can leverage knowledge across different scenarios."
  }
};

const AgentDiagram = ({ isSingleAgent }) => (
  <svg 
    width="100%" 
    height={isSingleAgent ? "200" : "300"} 
    viewBox={isSingleAgent ? "0 0 400 200" : "0 0 400 300"}
  >
    <defs>
      <marker 
        id="arrowhead" 
        markerWidth="10" 
        markerHeight="7" 
        refX={isSingleAgent ? "0" : "10"} 
        refY="3.5" 
        orient="auto"
      >
        <polygon points="0 0, 10 3.5, 0 7" />
      </marker>
    </defs>

    {isSingleAgent ? (
      <>
        <rect x="50" y="80" width="100" height="40" fill="lightgray" stroke="black" />
        <text x="100" y="105" textAnchor="middle">Agent</text>
        
        <ellipse cx="250" cy="100" rx="80" ry="40" fill="lightgray" stroke="black" />
        <text x="250" y="105" textAnchor="middle">Environment</text>
        
        <path d="M100 80 L100 25 L100 25 L250 25 L250 50" fill="none" stroke="black" markerEnd="url(#arrowhead)" />
        <text x="170" y="20" textAnchor="middle">Action</text>
        
        <path d="M250 140 L250 170 L100 170 L100 130" fill="none" stroke="black" markerEnd="url(#arrowhead)" />
        <text x="180" y="185" textAnchor="middle" fontSize="10">Observation, Reward, Info</text>
      </>
    ) : (
      <>
        <rect x="120" y="50" width="100" height="40" fill="lightgray" stroke="black" />
        <text x="170" y="75" textAnchor="middle" fill="black">Player 1</text>

        <rect x="120" y="250" width="100" height="40" fill="lightgray" stroke="black" />
        <text x="170" y="275" textAnchor="middle" fill="black">Player 2</text>

        <ellipse cx="300" cy="160" rx="80" ry="30" fill="lightgray" stroke="black" />
        <text x="300" y="165" textAnchor="middle" fill="black">Environment Step 1</text>

        <ellipse cx="50" cy="160" rx="80" ry="30" fill="lightgray" stroke="black" />
        <text x="50" y="165" textAnchor="middle" fill="black">Environment Step 2</text>

        <path d="M220 70 L300 130" fill="none" stroke="black" markerEnd="url(#arrowhead)" />
        <path d="M300 190 L220 270" fill="none" stroke="black" markerEnd="url(#arrowhead)" />
        <path d="M120 270 L50 190" fill="none" stroke="black" markerEnd="url(#arrowhead)" />
        <path d="M50 130 L120 70" fill="none" stroke="black" markerEnd="url(#arrowhead)" />
      </>
    )}
  </svg>
);

const HowItWorks = ({ competitionInfo }) => {
  const isSingleAgent = competitionInfo.number_of_agents === 1;
  const relevantTags = (competitionInfo.tags || []).filter(tag => 
    Object.keys(tagDescriptions).includes(tag.name.toUpperCase())
  );
  
  return (
    <>
      <Text mt="md" size="lg">
        This competition combines multiple unique aspects that shape how agents interact and compete:
      </Text>
      
      <Stack spacing="xl" mt="xl">
        {relevantTags.map((tag) => {
          const tagInfo = tagDescriptions[tag.name.toUpperCase()];
          return tagInfo ? (
            <div key={tag.name}>
              <Text weight={500} size="xl" mb="md">
                {tagInfo.title}
              </Text>
              <Text>
                {tagInfo.description}
              </Text>
            </div>
          ) : null;
        })}
      </Stack>
      
      
      <div style={styles.diagram}>
        <AgentDiagram isSingleAgent={isSingleAgent} />
      </div>
    </>
  );
};

const SubmissionRequirements = ({ 
  competitionInfo, 
  environmentInfo,
  runtimeOptions,
  selectedRuntime,
  setSelectedImage,
  handleJoinCompetition 
}) => {
  // This template should come from the backend based on environment type
  const navigate = useNavigate();
  const agentTemplate = competitionInfo.agent_template || `class Agent:
    def __init__(self, env):
        self.env = env
        # Initialize your model here
        # Example: self.model = torch.load('model.pt')

    def choose_action(self, observation, reward=0.0, terminated=False, truncated=False, info=None, action_mask=None):
        # Implement your action selection logic
        # Example: return self.model(observation)
        return action`;

  return (
    <Stack spacing="xl">
      <div>
        <Title order={3}>What is a Submission?</Title>
        <Text mt="md">
          A submission consists of your agent's code and model files that will run in your selected runtime environment.
          The main requirements are:
        </Text>
        <div style={styles.constraintsWrapper}>
          <div style={styles.constraintItem}>
            <Text weight={500}>Required Files</Text>
            <Text size="sm" c="dimmed">• agent.py with Agent class</Text>
            <Text size="sm" c="dimmed">• Model weights (if using ML)</Text>
            <Text size="sm" c="dimmed">• Additional helper modules</Text>
          </div>
          <div style={styles.constraintItem}>
            <Text weight={500}>Storage Limits</Text>
            <Text size="sm" c="dimmed">• Total size: {competitionInfo.agent_storage_limit}</Text>
            <Text size="sm" c="dimmed">• Max files: {competitionInfo.max_files}</Text>
          </div>
        </div>
      </div>
      
      <div>
        <Title order={3}>Agent Structure</Title>
        <Text mt="md" mb="md">
          Your agent.py must implement the following interface to interact with the environment:
        </Text>
        <div style={styles.codeWrapper}>
          <Code block>{agentTemplate}</Code>
        </div>
      </div>
      
      <div>
        <Title order={3}>Compute Constraints</Title>
        <Text mt="md">
          Your agent must operate within these limits during both validation and competition:
        </Text>
        <div style={styles.constraintsWrapper}>
          <div style={styles.constraintItem}>
            <Text weight={500}>Resources</Text>
            <Text size="sm" c="dimmed">CPU: {competitionInfo.agent_cpu_limit}</Text>
            <Text size="sm" c="dimmed">Memory: {competitionInfo.agent_memory_limit}</Text>
          </div>
          <div style={styles.constraintItem}>
            <Text weight={500}>Performance</Text>
            <Text size="sm" c="dimmed">Time per step: {competitionInfo.agent_max_time_per_step_second}s</Text>
            <Text size="sm" c="dimmed">Action Space: {JSON.stringify(environmentInfo.state_shape)}</Text>
            <Text size="sm" c="dimmed">Observation Space: {JSON.stringify(environmentInfo.observation_shape)}</Text>
          </div>
        </div>
      </div>

      <div>
  <Title order={3}>Runtime Environment</Title>
  <Text mt="md">
    Runtime environments are pre-configured containers where your agent will run. Each environment comes with specific 
    Python version and ML frameworks installed, ensuring consistent execution across development and competition.
  </Text>
  
  <Text mt="md" mb="lg">
    Available runtime environments for this competition:
  </Text>
  
  <div style={styles.constraintsWrapper}>
    <RuntimeSelector
      runtimeOptions={runtimeOptions}
      selectedRuntime={selectedRuntime}
      setSelectedImage={setSelectedImage}
      isViewOnly={true} // New prop to make it display-only
    />
  </div>
  
  <Text size="sm" c="dimmed" mt="md">
    When submitting your agent, you'll select one of these environments based on your implementation's requirements. 
    Make sure your code is compatible with your chosen environment's Python version and installed packages.
  </Text>
</div>

      <Stack spacing="md">
        <Title order={3}>Submission Process</Title>
        <Group align="center" spacing="sm">
          <Text>1. Click</Text>
          <StyledButton
            size="md"
            variant="primary"
            onClick={handleJoinCompetition}
          >
            Submit Agent
          </StyledButton>
          <Text>and provide a name for your agent</Text>
        </Group>
        <Text>
          2. Upload your code files (within the {competitionInfo.agent_storage_limit} limit)
        </Text>
        <Text>
          3. Deploy your agent for validation and initial scoring:
        </Text>
        <Stack spacing="xs" ml="md">
          <Text size="sm" c="dimmed">• Validation: checks if your agent respects the compute constraints</Text>
          <Text size="sm" c="dimmed">• Multiple test runs to establish initial ranking</Text>
          <Text size="sm" c="dimmed">• Integration into the daily competition schedule</Text>
        </Stack>
        <Text>
          4. After initial scoring, your agent will appear in the{' '}
          <Text 
            component="a" 
            href={`/viewcompetition/${competitionInfo.id}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/viewcompetition/${competitionInfo.id}`, { 
                state: { defaultTab: 'leaderboard' } 
              });
            }}
            variant="link"
            fw={600}
            style={{ 
              cursor: 'pointer',
              color: 'var(--mantine-color-blue-6)',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            Leaderboard
          </Text>
        </Text>
        <Text>
          5. Your agent will compete daily against others of similar rank
        </Text>
        <Text c="dimmed" mt="xs" size="sm">
          Note: While you can submit multiple agents and track them in 
          <Text component="a" href="/my-agents" variant="link"> My Agents</Text>,
          only your best performing agent's score will count towards your final ranking.
        </Text>
      </Stack>
    </Stack>
  );
};

const TrainingResources = ({ githubRepo, colabNotebook, competitionInfo }) => {
  const navigate = useNavigate();

  return (
    <Stack spacing="xl">
      <div>
        <Title order={3}>GitHub Repository</Title>
        <Text mt="md">
          Clone our repository to get everything you need to start developing your agent:
        </Text>
        <Button
          component="a"
          href={githubRepo}
          target="_blank"
          rel="noopener noreferrer"
          leftSection={<Github size={20} />}
          variant="light"
          mt="md"
        >
          View Repository
        </Button>
        <div style={styles.resourcesGrid} mt="lg">
          <div style={styles.resourceItem}>
            <Text weight={500}>Environment Setup</Text>
            <Text size="sm" c="dimmed">• Competition environment code</Text>
            <Text size="sm" c="dimmed">• Local development guide</Text>
            <Text size="sm" c="dimmed">• Dependencies management</Text>
          </div>
          
          <div style={styles.resourceItem}>
            <Text weight={500}>Starter Code</Text>
            <Text size="sm" c="dimmed">• Random agent template</Text>
            <Text size="sm" c="dimmed">• Basic agent implementation</Text>
            <Text size="sm" c="dimmed">• Example training loops</Text>
          </div>
          
          <div style={styles.resourceItem}>
            <Text weight={500}>Examples & Documentation</Text>
            <Text size="sm" c="dimmed">• Training scripts</Text>
            <Text size="sm" c="dimmed">• Step-by-step tutorials</Text>
            <Text size="sm" c="dimmed">• API documentation</Text>
          </div>
        </div>
      </div>

      {colabNotebook && (
        <div>
          <Title order={3}>Quick Start Notebook</Title>
          <Text mt="md">
            Get started quickly with our Google Colab notebook that includes working examples:
          </Text>
          <Button
            component="a"
            href={colabNotebook}
            target="_blank"
            rel="noopener noreferrer"
            leftSection={<Codesandbox size={20} />}
            variant="light"
            mt="md"
          >
            Open Notebook
          </Button>
        </div>
      )}

      <div>
        <Title order={3}>Community Support</Title>
        <Text mt="md">
          Have questions or need help? Join the discussion in our{' '}
          <Text
            component="a"
            href={`/viewcompetition/${competitionInfo.id}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/viewcompetition/${competitionInfo.id}`, {
                state: { defaultTab: 'discussions' }
              });
            }}
            variant="link"
            fw={500}
            style={{ cursor: 'pointer' }}
          >
            Competition Discussions
          </Text>
          {' '}section. Our community is here to help!
        </Text>
        
        <Group mt="lg" spacing="xs">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/viewcompetition/${competitionInfo.id}`, {
                state: { defaultTab: 'discussions' }
              });
            }}
            variant="light"
            leftSection={<MessageCircle size={20} />}
          >
            Join Discussion
          </Button>
        </Group>
      </div>

      {!githubRepo && (
        <Alert color="yellow">
          Repository information will be available soon. Check back later!
        </Alert>
      )}
    </Stack>
  );
};

export default GettingStarted;