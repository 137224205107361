import React, { useEffect } from 'react';
import { Modal, Title, LoadingOverlay, Alert, Stack } from '@mantine/core';
import { useVideoReplayStream } from 'hooks/useVideoReplayStream';
import VideoDisplay from 'components/Competition/VideoDisplay';

const GameReplayModal = ({ 
  isOpen, 
  onClose, 
  competitionId,
  gcsPath,
  gameInfo,
  renderDelay = 1
}) => {
  const {
    verifyAndStartStream,
    socket,
    isConnected,
    verifyStatus,
    cleanup
  } = useVideoReplayStream(competitionId);

  // Predefined colors for agents
  const agentColors = [
    'black', //TODO
  ];
  // Format the fileNameInfo to include both main agent and opponents
  const formatFileInfo = () => {
    const allAgents = [
      {
        agent_name: gameInfo?.agent_performance?.agent_name,
        user_name: gameInfo?.agent_performance?.user_name,
        reward: gameInfo?.agent_performance?.reward
      },
      ...(gameInfo?.opponents || []).map(opponent => ({
        agent_name: opponent.agent_name,
        user_name: opponent.user_name,
        reward: opponent.reward
      }))
    ];

    const fileInfo = {
      [gcsPath]: allAgents.reduce((acc, agent, index) => {
        acc[`player_${index}`] = {
          agent_name: agent.agent_name || 'Unknown',
          user_name: agent.user_name || 'Unknown',
          color: agentColors[index] || agentColors[0]
        };
        return acc;
      }, {})
    };

    return fileInfo;
  };

  useEffect(() => {
    if (isOpen && gcsPath) {
      verifyAndStartStream(gcsPath).catch(console.error);
    }
    return () => cleanup();
  }, [isOpen, gcsPath]);

  const handleClose = () => {
    cleanup();
    onClose();
  };

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      size="xl"
      title={<Title order={3}>Game Replay</Title>}
    >
      <Stack>
        {verifyStatus.error && (
          <Alert color="red" title="Error">
            {verifyStatus.error}
          </Alert>
        )}
        
        <div style={{ position: 'relative', minHeight: '400px' }}>
          <LoadingOverlay 
            visible={verifyStatus.loading || !isConnected} 
            blur={2}
          />
          
          {socket && isConnected && (
            <VideoDisplay
              socket_current={socket}
              fileName={gcsPath}
              fileNameInfo={formatFileInfo()[gcsPath]}
              renderDelay={renderDelay}
              isGcpFile={true}
            />
          )}
        </div>
      </Stack>
    </Modal>
  );
};

export default GameReplayModal;