import React from 'react';
import { 
  Card,
  Text,
  Button,
  NumberInput,
  Select,
  TextInput,
  Group,
  Stack,
  Modal,
  Accordion,
  Textarea
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';

const ConfigurationSection = ({
  configurationId,
  configurationData,
  updateConfigurationData,
  handleSaveConfiguration,
  handleDeleteConfiguration
}) => {
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);

  const handleSave = async () => {
    try {
      await handleSaveConfiguration();
      notifications.show({
        title: 'Success',
        message: 'Configuration saved successfully',
        color: 'green'
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to save configuration',
        color: 'red'
      });
    }
  };

  const handleDelete = async () => {
    try {
      await handleDeleteConfiguration();
      notifications.show({
        title: 'Success',
        message: 'Configuration deleted successfully',
        color: 'green'
      });
      closeDeleteModal();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to delete configuration',
        color: 'red'
      });
    }
  };

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section withBorder inheritPadding py="xs">
        <Text fw={500} size="lg">Competition Configuration</Text>
      </Card.Section>

      <Stack spacing="md" mt="md">
        <Accordion>
          {/* Basic Configuration */}
          <Accordion.Item value="basic">
            <Accordion.Control>Basic Configuration</Accordion.Control>
            <Accordion.Panel>
              <Stack spacing="md">
                <NumberInput
                  label="Number of Agents"
                  value={configurationData.number_of_agents}
                  onChange={(value) => updateConfigurationData({ number_of_agents: value })}
                  min={1}
                  max={100}
                  required
                />

                <Select
                  label="Engine"
                  data={configurationData.engines}
                  value={configurationData.engine_id}
                  onChange={(value) => updateConfigurationData({ engine_id: value })}
                  required
                />

                <TextInput
                  label="Notebook Blueprint (Colab)"
                  value={configurationData.notebook_blueprint_colab}
                  onChange={(e) => updateConfigurationData({ notebook_blueprint_colab: e.target.value })}
                />
                <TextInput
                  label="github_repo"
                  value={configurationData.github_repo}
                  onChange={(e) => updateConfigurationData({ github_repo: e.target.value })}
                />            
              <Textarea
                label="Agent Template"
                value={configurationData.agent_template}
                onChange={(e) => updateConfigurationData({ agent_template: e.target.value })}
                minRows={4}
                maxRows={8}
                autosize
                placeholder="Enter your agent template code here..."
                styles={{ input: { fontFamily: 'monospace' }}}
              />
              <Select
                label="Simulation Version"
                data={['rl', 'supervised', 'rl_continuous_info']}
                value={configurationData.simulation_version}
                onChange={(value) => updateConfigurationData({ simulation_version: value })}
              />
              <Select
                label="Kubernetes Workload Value"
                data={['scalable', 'high-memory']}
                value={configurationData.k8s_workload_value}
                onChange={(value) => updateConfigurationData({ k8s_workload_value: value })}
              />
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>

          {/* Timing Configuration */}
          <Accordion.Item value="timing">
            <Accordion.Control>Timing Configuration</Accordion.Control>
            <Accordion.Panel>
              <Stack spacing="md">
                <NumberInput
                  label="Agent Max Time Per Step (seconds)"
                  value={configurationData.agent_max_time_per_step_second}
                  onChange={(value) => updateConfigurationData({ agent_max_time_per_step_second: value })}
                  precision={2}
                  min={0.1}
                />

                <NumberInput
                  label="Environment Max Time Per Step (seconds)"
                  value={configurationData.env_max_time_per_step_second}
                  onChange={(value) => updateConfigurationData({ env_max_time_per_step_second: value })}
                  precision={2}
                  min={0.1}
                />

                <NumberInput
                  label="Simulation Timeout (seconds)"
                  value={configurationData.simulation_timeout_sec}
                  onChange={(value) => updateConfigurationData({ simulation_timeout_sec: value })}
                  precision={2}
                  min={1}
                />

                <NumberInput
                  label="Simulation Max Steps"
                  value={configurationData.simulation_max_steps}
                  onChange={(value) => updateConfigurationData({ simulation_max_steps: value })}
                  min={1}
                />

                <NumberInput
                  label="Render Delay (seconds)"
                  value={configurationData.render_delay_second}
                  onChange={(value) => updateConfigurationData({ render_delay_second: value })}
                  precision={2}
                  min={0}
                />

                <NumberInput
                  label="Render End Time (seconds)"
                  value={configurationData.render_end_second}
                  onChange={(value) => updateConfigurationData({ render_end_second: value })}
                  precision={2}
                  min={0}
                />
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>

          {/* Environment Resources */}
          <Accordion.Item value="env-resources">
            <Accordion.Control>Environment Resources</Accordion.Control>
            <Accordion.Panel>
              <Stack spacing="md">
                <TextInput
                  label="CPU Limit"
                  value={configurationData.env_cpu_limit}
                  onChange={(e) => updateConfigurationData({ env_cpu_limit: e.target.value })}
                />

                <TextInput
                  label="CPU Request"
                  value={configurationData.env_cpu_request}
                  onChange={(e) => updateConfigurationData({ env_cpu_request: e.target.value })}
                />

                <TextInput
                  label="Memory Limit"
                  value={configurationData.env_memory_limit}
                  onChange={(e) => updateConfigurationData({ env_memory_limit: e.target.value })}
                />

                <TextInput
                  label="Memory Request"
                  value={configurationData.env_memory_request}
                  onChange={(e) => updateConfigurationData({ env_memory_request: e.target.value })}
                />

                <TextInput
                  label="Ephemeral Storage Limit"
                  value={configurationData.env_ephemeral_storage_limit}
                  onChange={(e) => updateConfigurationData({ env_ephemeral_storage_limit: e.target.value })}
                />
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>

          {/* Agent Resources */}
          <Accordion.Item value="agent-resources">
            <Accordion.Control>Agent Resources</Accordion.Control>
            <Accordion.Panel>
              <Stack spacing="md">
                <TextInput
                  label="CPU Limit"
                  value={configurationData.agent_cpu_limit}
                  onChange={(e) => updateConfigurationData({ agent_cpu_limit: e.target.value })}
                />

                <TextInput
                  label="CPU Request"
                  value={configurationData.agent_cpu_request}
                  onChange={(e) => updateConfigurationData({ agent_cpu_request: e.target.value })}
                />

                <TextInput
                  label="Memory Limit"
                  value={configurationData.agent_memory_limit}
                  onChange={(e) => updateConfigurationData({ agent_memory_limit: e.target.value })}
                />

                <TextInput
                  label="Memory Request"
                  value={configurationData.agent_memory_request}
                  onChange={(e) => updateConfigurationData({ agent_memory_request: e.target.value })}
                />

                <TextInput
                  label="Ephemeral Storage Limit"
                  value={configurationData.agent_ephemeral_storage_limit}
                  onChange={(e) => updateConfigurationData({ agent_ephemeral_storage_limit: e.target.value })}
                />

                <TextInput
                  label="Ephemeral Storage Request"
                  value={configurationData.agent_ephemeral_storage_request}
                  onChange={(e) => updateConfigurationData({ agent_ephemeral_storage_request: e.target.value })}
                />
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>

          {/* Simulation Resources */}
          <Accordion.Item value="simulation-resources">
            <Accordion.Control>Simulation Resources</Accordion.Control>
            <Accordion.Panel>
              <Stack spacing="md">
                <TextInput
                  label="CPU Limit"
                  value={configurationData.simulation_cpu_limit}
                  onChange={(e) => updateConfigurationData({ simulation_cpu_limit: e.target.value })}
                />

                <TextInput
                  label="CPU Request"
                  value={configurationData.simulation_cpu_request}
                  onChange={(e) => updateConfigurationData({ simulation_cpu_request: e.target.value })}
                />

                <TextInput
                  label="Memory Limit"
                  value={configurationData.simulation_memory_limit}
                  onChange={(e) => updateConfigurationData({ simulation_memory_limit: e.target.value })}
                />

                <TextInput
                  label="Memory Request"
                  value={configurationData.simulation_memory_request}
                  onChange={(e) => updateConfigurationData({ simulation_memory_request: e.target.value })}
                />

                <TextInput
                  label="Service Account Name"
                  value={configurationData.simulation_service_account_name}
                  onChange={(e) => updateConfigurationData({ simulation_service_account_name: e.target.value })}
                />
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>

          {/* Docker Image Configuration */}
          <Accordion.Item value="docker-config">
            <Accordion.Control>Docker Configuration</Accordion.Control>
            <Accordion.Panel>
              <Stack spacing="md">
                <Select
                  label="Worker Environment Agent Image"
                  data={configurationData.formattedDockerKernelVersion}
                  value={String(configurationData.docker_image_worker_envagent_id)}
                  onChange={(value) => updateConfigurationData({ docker_image_worker_envagent_id: parseInt(value) })}
                />

                <Select
                  label="Environment Runtime Image"
                  data={configurationData.formattedDockerKernelVersion}
                  value={String(configurationData.docker_image_env_runtime_id)}
                  onChange={(value) => updateConfigurationData({ docker_image_env_runtime_id: parseInt(value) })}
                />

                <Select
                  label="Image Pull Policy"
                  data={[
                    { value: 'IfNotPresent', label: 'If Not Present' },
                    { value: 'Always', label: 'Always' },
                    { value: 'Never', label: 'Never' }
                  ]}
                  value={configurationData.env_agent_image_policy}
                  onChange={(value) => updateConfigurationData({ env_agent_image_policy: value })}
                />
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Group position="right" mt="xl">
          <Button variant="filled" onClick={handleSave}>
            Save Configuration
          </Button>
          
          {configurationId && (
            <Button variant="light" color="red" onClick={openDeleteModal}>
              Delete Configuration
            </Button>
          )}
        </Group>
      </Stack>

      <Modal
        opened={deleteModalOpened}
        onClose={closeDeleteModal}
        title="Delete Configuration"
        centered
      >
        <Stack>
          <Text>Are you sure you want to delete this configuration? This action cannot be undone.</Text>
          <Group position="right">
            <Button variant="subtle" onClick={closeDeleteModal}>Cancel</Button>
            <Button variant="filled" color="red" onClick={handleDelete}>
              Delete
            </Button>
          </Group>
        </Stack>
      </Modal>
    </Card>
  );
};

export default ConfigurationSection;