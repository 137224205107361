import React, { useState, useRef, useCallback, useEffect } from "react";
import { Box, Image, Loader, Center, Group, Button, Slider, Tooltip, Select, Text } from "@mantine/core";
import { IconPlayerPlay, IconPlayerPause, IconMaximize, IconMinimize } from "@tabler/icons-react";

const VideoPlayer = ({ imageData, isLoading, isPlaying, controls }) => {
  const [showControls, setShowControls] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef(null);

  const speedOptions = [
    { value: "0.01", label: "0.01x" },
    { value: "0.10", label: "0.10x" },
    { value: "0.25", label: "0.25x" },
    { value: "1", label: "1x" },
    { value: "4", label: "4x" },
    { value: "10", label: "10x" },
    { value: "100", label: "100x" }
  ];

  useEffect(() => {
    if (!isPlaying) {
      setShowControls(true);
    }
  }, [isPlaying]);

  const toggleFullscreen = useCallback(async () => {
    if (!document.fullscreenElement) {
      try {
        await containerRef.current.requestFullscreen();
        setIsFullscreen(true);
      } catch (err) {
        console.error('Error attempting to enable fullscreen:', err);
      }
    } else {
      try {
        await document.exitFullscreen();
        setIsFullscreen(false);
      } catch (err) {
        console.error('Error attempting to exit fullscreen:', err);
      }
    }
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleMouseLeave = () => {
    if (isPlaying) {
      setShowControls(false);
    }
  };

  return (
    <Box 
      ref={containerRef}
      pos="relative" 
      className={`${isFullscreen ? 'h-screen w-screen' : 'w-96 h-64'}`}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={handleMouseLeave}
      sx={{
        '&:fullscreen': {
          backgroundColor: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }
      }}
    >
      <Box 
        className={`relative flex items-center justify-center ${
          isFullscreen ? 'h-screen w-screen' : 'h-64 w-96'
        }`}
        bg="black"
      >
        {isLoading && !isPlaying ? (
          <Center className="h-full">
            <Loader color="white" size="xl" />
          </Center>
        ) : (
          <Image
            src={imageData}
            alt="Live Stream"
            fit="contain"
            className="h-screen w-screen"
            styles={{
              root: {
                height: isFullscreen ? '100vh' : '100%',
                width: isFullscreen ? '100vw' : '100%',
                backgroundColor: 'black',
              },
              figure: { height: '100%', width: '100%' },
              image: {
                objectFit: 'contain',
                height: '100%',
                width: '100%',
              }
            }}
          />
        )}
      </Box>



      {/* Bottom controls */}
      <Box
        pos="absolute"
        bottom={0}
        left={0}
        right={0}
        style={{
          background: 'linear-gradient(transparent, rgba(0,0,0,0.8))',
          opacity: showControls ? 1 : 0,
          transition: 'all 0.3s ease',
          pointerEvents: showControls ? 'auto' : 'none',
          padding: '20px 16px 12px',
        }}
      >
        <Slider
          value={controls.currentFrame}
          min={0}
          max={controls.totalFrames - 1}
          onChange={value => controls.handleFrameSelection({ target: { value } })}
          size="xs"
          color="white"
          styles={{
            track: { 
              cursor: 'pointer',
              '&:hover': {
                '&::before': {
                  backgroundColor: 'white',
                }
              }
            },
            bar: { backgroundColor: 'white' },
            thumb: { 
              backgroundColor: 'white',
              cursor: 'pointer',
              width: '12px',
              height: '12px',
              display: 'none',
            },
            root: {
              '&:hover': {
                '.mantine-Slider-thumb': {
                  display: 'block',
                }
              }
            }
          }}
          mb="xs"
        />

        <Group align="center" justify="space-between" className="w-full">
          <Group spacing="md">
            <Button
              variant="transparent"
              onClick={controls.togglePlayPause}
              p={0}
              styles={{
                root: {
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    opacity: 0.8,
                  }
                }
              }}
            >
              {isPlaying ? 
                <IconPlayerPause size={24} stroke={2.5} /> : 
                <IconPlayerPlay size={24} stroke={2.5} />
              }
            </Button>

            <Text className="text-white text-sm font-medium" style={{ color: 'white' }}>
              {controls.currentFrame + 1} / {controls.totalFrames}
            </Text>
          </Group>

          <Group spacing="md">
            <Select
              value={controls.speed.toString()}
              onChange={controls.onSpeedChange}
              data={speedOptions}
              size="xs"
              w={85}
              styles={{
                input: {
                  background: 'rgba(28, 28, 28, 0.7)',
                  border: 'none',
                  color: 'white',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: 'rgba(28, 28, 28, 0.9)',
                  }
                },
                dropdown: {
                  background: 'rgba(28, 28, 28, 0.95)',
                  border: 'none',
                  color: 'white',
                  padding: '4px 0',
                },
                item: {
                  color: 'white',
                  padding: '4px 8px',
                  '&[data-selected]': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  },
                  '&[data-hovered]': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }
                },
                rightSection: {
                  color: 'white',
                },
              }}
              zIndex={1000}
            />

            <Button
              variant="transparent"
              onClick={toggleFullscreen}
              p={0}
              styles={{
                root: {
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    opacity: 0.8,
                  }
                }
              }}
            >
              {isFullscreen ? 
                <IconMinimize size={24} stroke={2.5} /> : 
                <IconMaximize size={24} stroke={2.5} />
              }
            </Button>
          </Group>
        </Group>
      </Box>
    </Box>
  );
};

export default VideoPlayer;