// VideoDisplay.jsx
import React, { useEffect, useRef, useState } from "react";
import { Card, Group, Stack, Text, Badge } from "@mantine/core";
import VideoPlayer from "components/Competition/VideoPlayer";

const VideoDisplay = ({
  socket_current,
  fileName,
  fileNameInfo,
  renderDelay,
  isGcpFile = false
}) => {
  const [imageData, setImageData] = useState("/livewillstartsoon.png");
  const [nbStep, setNbStep] = useState("");
  const [rewards, setRewards] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [selectedFrame, setSelectedFrame] = useState(0);
  const [totalFrames, setTotalFrames] = useState(0);
  const videoFilesRef = useRef({});
  const processing = useRef(false);
  const timeoutIds = useRef([]);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);

  const clearAllTimeouts = () => {
    timeoutIds.current.forEach(clearTimeout);
    timeoutIds.current = [];
  };

  const handleSpeedChange = (value) => {
    const newSpeed = parseFloat(value);
    setPlaybackSpeed(newSpeed);
    stopPlayback();
    
    // Reset to first frame
    const fileData = videoFilesRef.current[fileName];
    if (fileData && fileData.renderStates.length > 0) {
      setCurrentFrame(0);
      setSelectedFrame(0);
      setImageData(fileData.renderStates[0].imageData);
      setNbStep(`${fileData.renderStates[0].infos.current_step}`);
      setRewards(Object.values(fileData.renderStates[0].infos.cum_rewards));
    }
  };

  const stopPlayback = () => {
    setIsPlaying(false);
    processing.current = false;
    clearAllTimeouts();
  };

  const processBufferItem = (fileData, startIndex = 0) => {
    if (!fileData || fileData.renderStates.length === 0) {
      console.log("No frames available or empty file data");
      return;
    }

    let index = startIndex;
    let endWaitCounter = 0;
    const endWaitLimit = 25;

    setTotalFrames(fileData.renderStates.length);
    processing.current = true;

    const displayNextFrame = () => {
      if (!processing.current) {
        clearAllTimeouts();
        return;
      }

      if (index < fileData.renderStates.length) {
        const frame = fileData.renderStates[index];
        setImageData(frame.imageData);
        setNbStep(`${frame.infos.current_step}`);
        setRewards(Object.values(frame.infos.cum_rewards));
        setCurrentFrame(index);
        timeoutIds.current.push(
          setTimeout(displayNextFrame, 1000 * renderDelay / playbackSpeed)
        );
        index++;
      } else if (endWaitCounter < endWaitLimit) {
        endWaitCounter++;
        timeoutIds.current.push(
          setTimeout(displayNextFrame, 1000 * renderDelay / playbackSpeed)
        );
      }
    };

    setIsPlaying(true);
    displayNextFrame();
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      stopPlayback();
    } else {
      const fileData = videoFilesRef.current[fileName];
      if (fileData) {
        processing.current = true;
        processBufferItem(fileData, selectedFrame);
      }
    }
  };

  const handleFrameSelection = (event) => {
    const frameIndex = Number(event.target.value);
    setSelectedFrame(frameIndex);
    if (isPlaying) {
      stopPlayback();
    }
    const fileData = videoFilesRef.current[fileName];
    if (fileData) {
      setCurrentFrame(frameIndex);
      setImageData(fileData.renderStates[frameIndex].imageData);
      setNbStep(`${fileData.renderStates[frameIndex].infos.current_step}`);
      setRewards(
        Object.values(fileData.renderStates[frameIndex].infos.cum_rewards)
      );
    }
  };

    useEffect(() => {
      if (!fileName || !socket_current) return;

      stopPlayback();
      setImageData("/livewillstartsoon.png");
      setNbStep("");
      setRewards([]);
      setIsLoading(true);
      setSelectedFrame(0);

      const fileData = videoFilesRef.current[fileName];
      if (fileData?.renderStates.length > 0 && fileData.isStreamCompleted) {
        setIsLoading(false);
        processing.current = true;
        processBufferItem(fileData);
      } else {
        // Request file streaming with GCP path info
        socket_current.emit("request_stream_file", { 
          fileName,
          isGcpFile 
        });
      }
    }, [fileName, socket_current, isGcpFile]);

    useEffect(() => {
      if (!socket_current) return;

      const handleRender = (data) => {
        const fileData = videoFilesRef.current[data.fileName] || {
          renderStates: [],
          isStreamCompleted: false,
        };

        if (data.isStreamCompleted) {
          fileData.isStreamCompleted = true;
        } else {
          fileData.renderStates.push({
            imageData: data.image_data,
            infos: data.infos,
          });
        }

        videoFilesRef.current = {
          ...videoFilesRef.current,
          [data.fileName]: fileData,
        };

        if (data.isStreamCompleted && data.fileName === fileName) {
          setIsLoading(false);
          processing.current = true;
          processBufferItem(fileData);
        }
      };

      const handleError = (data) => {
        console.error(`Error streaming file: ${data.fileName}, Error: ${data.error}`);
        setIsLoading(false);
      };

      const handleDisconnect = () => {
        console.log("Socket disconnected, stopping playback.");
        stopPlayback();
        setIsLoading(false);
      };

      socket_current.on("render", handleRender);
      socket_current.on("file_error", handleError);
      socket_current.on("disconnect", handleDisconnect);

      return () => {
        socket_current.off("render", handleRender);
        socket_current.off("file_error", handleError);
        socket_current.off("disconnect", handleDisconnect);
        stopPlayback();
      };
    }, [fileName, socket_current]);

    return (
      <Card padding="md">
        <Stack>
          <VideoPlayer
            imageData={imageData}
            isLoading={isLoading}
            isPlaying={isPlaying}
            controls={{
              togglePlayPause,
              currentFrame,
              totalFrames,
              handleFrameSelection,
              speed: playbackSpeed,
              onSpeedChange: handleSpeedChange
            }}
          />
          {fileNameInfo && (
            <>
              <Stack spacing="xs">
                {Object.entries(fileNameInfo).map(([key, info], idx) => (
                  <Group key={idx} justify="apart">
                    <Text>
                      <strong>Agent:</strong> {info.agent_name || "N/A"}
                    </Text>
                    <Text>
                      <strong>User:</strong> {info.user_name}
                    </Text>
                    <Text>
                      <strong>Reward:</strong> {rewards[idx] || 0}
                    </Text>
                    <Badge
                      styles={(theme) => ({
                        root: {
                          backgroundColor: info.color,
                        },
                      })}
                    >
                      &nbsp;
                    </Badge>
                  </Group>
                ))}
              </Stack>
              <Text>Step: {nbStep}</Text>
            </>
          )}
        </Stack>
      </Card>
    );
  };
  
  export default VideoDisplay;